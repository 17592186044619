import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowDown } from 'react-icons/fi'; // Import the icon
import './Home.css';

function Home() {
  
  const navigate = useNavigate();

  const handleKnowMoreClick = () => {
    navigate('/CS1');
    // window.open('/CS1', '_blank');
  };


  const handleKnowMoreClick2 = () => {
    navigate('/CS2');
  };


  const handleKnowMoreClickCM = () => {
    navigate('/CM');
  };


  const handleKnowMoreClickRanger = () => {
    navigate('/Ranger');
  };


  const handleKnowMoreClickPocus = () => {
    navigate('/Pocus');
  };


  const handleKnowMoreClickSG = () => {
    navigate('/SG');
  };

  const handleKnowMoreClick3 = () => {
    navigate('/CS3');
  };

  return (
    // main div of the website 
    <div className='parentDiv'> 
        {/* Section one content Hi image and intro text  */}
        <div className='section1-intro'>
                    {/* image showing memoji of hello guesture  */}
                    
                    <div className='text-intro'>
                          <h1 className='text-body-description'>
                          Empowering your Businesses with Scalable & Affordable Software Solutions.
 We design, develop, and deploy high-quality SaaS and on-premises software tailored for B2B and B2C businesses. Our solutions help small and medium-scale industries streamline operations, enhance efficiency, and maximize profitability. Whether you need a website, web application, or enterprise SaaS, we ensure affordability without compromising quality. Let’s build something great together! 🚀.
                          </h1>
                     </div>  
        </div>
        <div className='section2-intro'>
          <p>
          <FiArrowDown className='scroll-icon' /> {/* Icon before text */} Scroll down to explore more</p>
        </div>


        {/* **************************************** */}
        {/* Home page section 2, grid start  */}
        <div className='Container_HomePageCard' >
            {/* page title*/}
              <div className='Container_HomePageCard_title'>
                Our Services
              </div>
            

            {/* Card 1 */}
              <div className='Card1_Section_image'>
                  <div>
                      <img src={`${process.env.PUBLIC_URL}/CM.png`} alt="Description of Image"className='CardImage'/>
                  </div>
              </div>

        <div className='Card1_SectonInfo_Right'>
            <div className='CardTitle'>
            Custom SaaS Development
            </div>
            <div className='CardDescription'>
            We design and develop cloud-based SaaS applications tailored for B2B and B2C businesses. Our solutions are built for scalability, security, and seamless user experience, helping companies automate processes and drive growth.
            </div>
           
        </div>



{/* card 2 */}
 <div className='Card1_Section_image_for2'>
                  <div>
                      <img src={`${process.env.PUBLIC_URL}/NEWS.png`} alt="Description of Image"className='CardImage' />
                  </div>
              </div>

        <div className='Card1_SectonInfo_Right_for2'>
            <div className='CardTitle'>
            Web & Mobile Application Development
            </div>
            <div className='CardDescription'>
            We create user-friendly, responsive, and feature-rich web and mobile applications to enhance digital presence and customer engagement. Our solutions are optimized for performance, security, and scalability.
            </div>
            
        </div>


  {/* Card 3 */}
    <div className='Card1_Section_image_for3'>
        <div>
            <img src={`${process.env.PUBLIC_URL}/Ranger.png`} alt="Description of Image"className='CardImage' />
        </div>
    </div>

<div className='Card1_SectonInfo_Right_for3'>
  <div className='CardTitle'>
  On-Premises Software Solutions
  </div>
  <div className='CardDescription'>
  We develop on-premises software tailored to your business needs, ensuring data security, customization, and seamless integration with existing systems. Ideal for businesses that require offline access and full control over their infrastructure.
              
  </div>
  
</div>


{/* card 4 */}
<div className='Card1_Section_image_for4'>
                  <div>
                      <img src={`${process.env.PUBLIC_URL}/EasyWorkCard2.jpg`} alt="Description of Image"className='CardImage'/>
                  </div>
              </div>

        <div className='Card1_SectonInfo_Right_for4'>
            <div className='CardTitle' >
            Intuitive & User-Centric Designs
            </div>
            <div className='CardDescription'>
            Our expert designers craft engaging and functional UI/UX designs that enhance usability, improve user satisfaction, and drive conversions. We offer wireframing, prototyping, and full-scale design solutions.
            </div>
            
        </div>


  {/* Card 5 */}
  <div className='Card1_Section_image_for5'>
        <div>
            <img src={`${process.env.PUBLIC_URL}/Pocus.png`} alt="Description of Image"className='CardImage' />
        </div>
    </div>

<div className='Card1_SectonInfo_Right_for5'>
  <div className='CardTitle'>
  Full-Stack Development & Deployment
  </div>
  <div className='CardDescription'>
  From frontend to backend, we build powerful, scalable, and efficient software solutions. We handle everything from coding to cloud deployment, ensuring a smooth transition from development to production.
  </div>

</div>



{/* card 6 */}
<div className='Card1_Section_image_for6'>
                  <div>
                      <img src={`${process.env.PUBLIC_URL}/NEWS2.png`} alt="Description of Image"className='CardImage'/>
                  </div>
              </div>

        <div className='Card1_SectonInfo_Right_for6'>
            <div className='CardTitle'>
            Ongoing Support & Maintenance
            </div>
            <div className='CardDescription'>
            We provide continuous support, updates, and maintenance to ensure your software remains secure, up-to-date, and fully functional. Our team is available to troubleshoot issues, optimize performance, and scale as your business grows.
            </div>
            
        </div>
 </div>

</div> 
);
  
 
}

export default Home;
 