import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (

    <div className='footer-container'>
        <div className='section-left'>
            <div className='get-in-touch'>
            Let’s Collaborate on Your Next Project
            </div>
            <div className='emailid'>
              himalaysuslade@antrixum.com
            </div>
            <div className='address'>
            
            </div>
              
        </div>
        
        <div className='section-developedby'>
        <div className='DevelopedBy'>
        Copyright © 2025 Antrixum - All Rights Reserved.
            </div>
        </div>
    </div>
      
    );
}

export default Footer;
