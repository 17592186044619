import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const location = useLocation();
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/" onClick={() => setIsOpen(false)}>ANTRIXUM</Link>
      </div>
      <div className={`navbar-menu ${isOpen ? 'open' : ''}`}>
        <Link to="/" className={isActive('/')} onClick={() => setIsOpen(false)}></Link>
        <Link to="/words" className={isActive('/words')} onClick={() => setIsOpen(false)}></Link>
        <Link to="/about" className={isActive('/about')} onClick={() => setIsOpen(false)}></Link>
        <a href="/Prashant Pathak UX Designer.pdf" target="_blank" rel="noopener noreferrer" onClick={() => setIsOpen(false)}></a>
      </div>
      
    </nav>
  );
}

export default Navbar;
